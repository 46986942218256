import React from "react";
import Layout from "../components/layout";

const Contact = () => (
  <Layout
    title="Contact"
    metaDescription="Contact us for technical support, sales and licensing questions, or just to say hi!"
  >
    <p>
      For technical support: <br />
      <a href="mailto:help@readsummerbell.com">help@readsummerbell.com</a>
    </p>
    <p>
      For sales, licensing, or other inquiries: <br />
      <a href="mailto:info@readsummerbell.com">info@readsummerbell.com</a>
    </p>
    <p>
      To get in touch with our founder: <br />
      <a href="mailto:hillary@readsummerbell.com">hillary@readsummerbell.com</a>
    </p>
  </Layout>
);

export default Contact;
